<template>
  <v-app>
    <v-container class="my-6 pa-0" fluid>
      <v-card class="py-0">
        <div class="card-header-padding pb-0">
          <v-row class="mb-0">
            <v-col lg="2" sm="4" md="4" class="pb-0">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Үнэлгээ 2 -
                  <span
                    v-if="selectedProgram"
                    @click="_print2"
                    class="blue--text"
                    >{{ selectedProgram["STUDENT_GROUP_NAME"] }}
                  </span>
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="filteredLessons">{{
                    filteredLessons.length
                  }}</span>
                </p>

                <v-progress-linear
                  v-if="pullingCourseLoading"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <v-btn
                  v-else
                  small
                  color="green"
                  class="white--text mt-n2 mb-2"
                  @click="getCourseDatas()"
                  >Хөтөлбөрийн мэдээлэл харах</v-btn
                >
              </div>
            </v-col>
            <v-col cols="auto">
              <v-btn
                elevation="0"
                class="bg-gradient-danger"
                @click="saveAllLessonsToESIS()"
                dark
                >Хичээлүүд ESIS-рүү хадгалах</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="userData.role == 'superadmin'"
                elevation="0"
                class="bg-gradient-warning"
                @click="sendAllNull()"
                dark
                >Бүх сонгоныг засуулах
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="userData.role == 'superadmin'"
                elevation="0"
                class="bg-gradient-info"
                @click="handleTeacherAndClassGroupAnalytics(true)"
                dark
                >Хувь засуулах
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                elevation="0"
                class="bg-gradient-danger"
                @click="saveAllLessonScoresToESIS()"
                dark
                >Дүн ESIS-рүү хадгалах</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <small
          v-if="
            selectedProgram &&
            selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
          "
          @click="updateClassAVG()"
          style="cursor: pointer"
          class="ml-6"
          >(Сонгон) Долоо хоногт орох дундаж цаг:
          {{ selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE }}</small
        >
        <div class="card-header-padding pt-0">
          <v-progress-linear
            v-if="loading || filteredLessons.length == 0"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-data-table
            class="scoreTables"
            v-if="lessons"
            hide-default-footer
            hide-default-header
            :items-per-page="-1"
            :items="filteredLessons"
          >
            <template slot="header">
              <tr style="font-size: 13px">
                <th class="text-start font-weight-normal">No</th>
                <th class="text-start font-weight-normal">Ээлжит хичээл</th>
                <th class="text-start font-weight-normal">
                  ESIS-т хичээл үүссэн
                </th>
                <th class="text-start font-weight-normal">Багш</th>
                <th class="text-start font-weight-normal">Төрөл</th>
                <th class="text-start font-weight-normal">С.Т</th>
                <th class="pl-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-start font-weight-normal"
                      style="
                        border-bottom: 2px solid #dddddd;
                        padding-left: 5px;
                      "
                      >Баталгаажсан</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                      >Хувь</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="padding-left: 5px"
                      >Тоо</v-col
                    >
                  </v-row>
                </th>
                <th class="text-center font-weight-normal">Хянагдаж байгаа</th>
                <th class="text-center font-weight-normal">Цуцлагдсан</th>
                <th class="text-center font-weight-normal">Бүртгэсэн</th>
                <th class="text-center font-weight-normal">
                  Үнэлгээний дундаж
                </th>
                <th>Үйлдэл</th>
                <th
                  v-if="
                    selectedProgram &&
                    selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
                  "
                >
                  7 хоногийн цаг
                </th>
                <th style="background-color: #e8f5e9" class="green--text">
                  Жилийн эцсийн дүнгээр
                </th>
                <th>ESIS харах</th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="_print(item)">{{ item.index }}</td>
                <td class="text-start px-1" style="white-space: nowrap">
                  {{ item.courseInfo.COURSE_NAME }}

                  <!-- {{ item.courseInfo.SUBJECT_AREA_ID }} -->
                </td>
                <td class="text-center">
                  <v-progress-linear
                    v-if="item.lessonSavingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-icon
                    color="green"
                    v-else-if="item.esisSavedLessonData"
                    @click="getLessonData(item)"
                    >mdi-check-circle</v-icon
                  >
                  <v-btn
                    x-small
                    v-else
                    @click="saveEeljitLessonToEsis(item, false)"
                    class="red--text"
                    text
                    >Үүсгэх</v-btn
                  >
                </td>
                <td class="px-1">
                  <template>
                    <small
                      class="blue--text"
                      v-for="(teacher, teacherIndex) in item.byTeachers"
                      :key="teacherIndex"
                      ><span v-if="teacherIndex > 0">, </span
                      >{{ teacher.teacherDisplayName }}</small
                    >
                  </template>
                </td>
                <td
                  v-if="item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'"
                  class="px-1"
                >
                  <small class="px-1 red--text"> Сонгон</small>
                </td>
                <td v-else><small>Заавал</small></td>
                <td
                  style="background-color: #ffebee"
                  v-if="item && item.students.length <= 1"
                  class="text-center blue--text px-2"
                >
                  {{ item.students.length }}
                </td>
                <td
                  v-else-if="item && item.students.length > 1"
                  class="text-center blue--text px-2"
                >
                  {{ item.students.length }}
                </td>
                <td v-else class="text-center">-</td>
                <td class="pl-0">
                  <v-row no-gutters style="height: 100% !important">
                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                    >
                      <span
                        style="color: green"
                        v-if="_getConfirmationProcent(item) == 100"
                        color="green"
                        >100%</span
                      >
                      <span v-else-if="_getConfirmationProcent(item) == 0">
                        0%</span
                      >
                      <span v-else>
                        {{ _getConfirmationProcent(item).toFixed(1) }}%</span
                      >
                    </v-col>

                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="padding-left: 5px; color: green"
                    >
                      {{ getAnalystics(item, 1) }}</v-col
                    >
                  </v-row>
                </td>
                <td style="color: #db9943" class="text-center">
                  {{ getAnalystics(item, 2) }}
                </td>
                <td style="color: red" class="text-center">
                  {{ getAnalystics(item, 3) }}
                </td>
                <td style="color: purple" class="text-center">
                  {{ getAnalystics(item, 4) }}
                </td>
                <td class="text-center">
                  {{ getAnalystics(item, 5) }}
                </td>
                <td>
                  <v-progress-linear
                    v-if="item.loading2 == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-progress-linear
                    v-else-if="!item.students || !item.unelgee2Scores"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    v-else
                    dark
                    elevation="0"
                    x-small
                    class="bg-gradient-danger text-capitalize mx-2"
                    @click="_doUnelgee(item)"
                  >
                    баталгаaжуулах
                  </v-btn>
                </td>
                <td
                  class="text-center"
                  v-if="
                    selectedProgram &&
                    selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
                  "
                >
                  {{ item.AVG_CONTACT_HOURS }}
                </td>
                <td style="white-space: nowrap">
                  <p
                    class="mb-n2 blue--text"
                    style="font-size: 12px"
                    v-if="
                      gradingMethods[item.GRADING_METHOD_USED_FOR_FINAL_GRADE]
                    "
                  >
                    {{
                      gradingMethods[item.GRADING_METHOD_USED_FOR_FINAL_GRADE]
                        .name
                    }}
                  </p>
                  <v-progress-linear
                    v-if="item.scoresSavingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>

                  <small
                    v-else-if="
                      item.finalGradeSavedToEsis &&
                      Number(getAnalystics(item, 1)) ==
                        Number(item.students.length)
                    "
                    class="green--text"
                    >ESIS-д хадгалагдсан</small
                  >

                  <v-progress-linear
                    v-else-if="
                      !item.students ||
                      !item.unelgee2Scores ||
                      item.scoresSavingLoading == true
                    "
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    elevation="0"
                    text
                    x-small
                    v-else-if="
                      !item.finalGradeSavedToEsis &&
                      Number(getAnalystics(item, 1)) ==
                        Number(item.students.length)
                    "
                    class="red--text"
                    @click="saveSpecialLessonGrades(item, false)"
                    >Дүн ESIS-рүү хадгалах</v-btn
                  >
                  <v-btn
                    elevation="0"
                    text
                    x-small
                    v-if="
                      !item.scoresSavingLoading &&
                      item.finalGradeSavedToEsis &&
                      // (item.finalGradeSavedToEsisCounter < 2 ||
                      //   !item.finalGradeSavedToEsisCounter) &&
                      Number(getAnalystics(item, 1)) ==
                        Number(item.students.length)
                    "
                    class="red--text"
                    @click="saveSpecialLessonGrades(item, false)"
                    >Дахин хадгалах</v-btn
                  >
                  <p
                    v-if="
                      item.students &&
                      item.unelgee2Scores &&
                      !item.scoresSavingLoading &&
                      item.finalGradeSavedToEsisAt
                    "
                    class="mb-n1 mt-n2 blue--text"
                    style="font-size: 12px"
                  >
                    {{ item.finalGradeSavedToEsisAt | formatDate2 }}
                  </p>
                </td>
                <td>
                  <v-progress-linear
                    v-if="item.scoresShowingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    elevation="0"
                    v-else-if="item.finalGradeSavedToEsis"
                    x-small
                    text
                    class="green--text"
                    @click="showingEsisSavedScores(item)"
                  >
                    Дүн харах
                  </v-btn>
                </td>
                <td v-if="item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'">
                  <v-progress-linear
                    v-if="item.nullSendingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    v-else-if="
                      item.students &&
                      item.finalGradeSavedToEsis &&
                      Number(getAnalystics(item, 1)) ==
                        Number(item.students.length) &&
                      item.students.length != selectedProgram.students.length
                    "
                    elevation="0"
                    x-small
                    dark
                    :color="
                      item.finalEsisGradeExtraStudentsFixed ? 'green' : 'red'
                    "
                    class="text-capitalize"
                    @click="sendNull2(item)"
                  >
                    {{
                      item.finalEsisGradeExtraStudentsFixed
                        ? "Сонгон зассан"
                        : "Сонгон засуулах"
                    }}
                  </v-btn>
                </td>
                <td>
                  <v-progress-linear
                    v-if="!item.unelgee2Scores"
                    color="red"
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                  <div
                    class="pl-2"
                    style="width: 300px"
                    v-else-if="
                      item.finalGradeSavedToEsisAt &&
                      item.unelgee2Scores &&
                      findFaultyDate(item)
                    "
                  >
                    <small class="red--text">
                      <v-icon color="red">mdi-alert</v-icon> Анхны хадгалснаас
                      хойш дүн засагдсан байна, "даxин xадгалаx" ТОВЧОО дарж
                      ЭСИС-тай тулгаарай.
                    </small>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
    <v-dialog
      v-model="unelgeeDialog"
      height="500"
      width="1400"
      v-if="currentSelectedLesson && renderComponent"
      persistent
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col lg="6" md="6" sm="6" cols="6" class="mt-1">
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }}
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
            <v-col lg="3" md="3" sm="3" cols="3" class="mt-n5">
              <v-select
                v-model="selectedGradingMethod"
                label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
                item-text="name"
                item-value="value"
                :items="gradingMethods"
              >
              </v-select>
            </v-col>
            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="ml-2 my-0 py-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн (' + simpleCalculationModel + ')'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee22DialogManager
          class="pt-0"
          v-if="
            currentSelectedLesson &&
            currentSelectedLesson.students &&
            currentSelectedLesson.unelgee2Scores &&
            currentSelectedLesson.unelgee1Scores
          "
          v-bind="$attrs"
          :lesson="currentSelectedLesson"
          :selectedGradingMethod="selectedGradingMethod"
        >
        </ScoreInputUnelgee22DialogManager>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block: !important;" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-4" v-else>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee2"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showGradesFromEsisDialog"
      v-if="lessonStudentScores && renderComponent"
      width="1000"
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                ESIS-т бичсэн жилийн эцсийн дүн (Үнэлгээ 2) -
                {{ userData.school.currentYear }}
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>

        <v-simple-table class="mx-10 mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">No.</th>
                <th class="text-left">Нэр</th>
                <th class="text-left">Бүлэг</th>
                <th class="text-left">Эцсийн дүн</th>
                <th class="text-left">Түвшин</th>
                <th class="text-left">Хадгаласан огноо</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, sIndex) in lessonStudentScores"
                :key="'studentttt' + sIndex"
              >
                <td style="width: 1%">{{ sIndex + 1 }}</td>
                <td>
                  {{ student.FIRST_NAME }}
                  <span style="color: #9e9e9e">, {{ student.LAST_NAME }}</span>
                </td>
                <td class="blue--text">
                  {{ student.STUDENT_GROUP_NAME }}
                </td>
                <td>{{ student.GRADE_MARK }}</td>
                <td>{{ student.GRADE_CODE }}</td>
                <td>
                  {{
                    currentSelectedLesson.finalGradeSavedToEsisAt | formatDate
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card></v-dialog
    >

    <v-dialog
      v-model="showLessonEsisDataDialog"
      v-if="selectedEsisLesson"
      width="1000"
    >
      <v-card class="pa-4">
        <v-data-table
          :items="selectedEsisLesson.byTeachers"
          hide-default-footer
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal">No</th>
              <th class="text-start font-weight-normal">Хичээлийн нэр</th>
              <th class="text-start font-weight-normal">Багшийн нэр</th>
              <th class="text-start font-weight-normal">Хөтөлбөрийн нэр</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="selectedEsisLesson.esisSavedLessonData[item.teacherId]">
              <td>
                {{ item.index }}
              </td>
              <td>
                {{ selectedEsisLesson.courseInfo.COURSE_NAME }}
              </td>
              <td>{{ item.teacherDisplayName }}</td>
              <td v-if="selectedEsisLesson.esisSavedLessonData.programPlan">
                {{
                  selectedEsisLesson.esisSavedLessonData.programPlan
                    .PROGRAM_PLAN_NAME
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCoursesEsisDataDialog"
      v-if="coursesData"
      width="1000"
    >
      <v-card class="pa-4">
        <v-card-title
          class="px-0"
          v-if="
            coursesData.programPlan && coursesData.programPlan.PROGRAM_PLAN_NAME
          "
          ><strong>Хөтөлбөрийн нэр: </strong>
          {{ coursesData.programPlan.PROGRAM_PLAN_NAME }},
          {{ coursesData.programPlan.id }}</v-card-title
        >
        <v-data-table
          :items-per-page="-1"
          :items="coursesData.courses"
          hide-default-footer
          style="border-top: 1px solid #dddddd !important"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal" style="width: 2%">
                No
              </th>
              <th class="text-start font-weight-normal">Хичээлийн нэр</th>
              <th class="text-start font-weight-normal">7 хоногийн цаг</th>
              <th class="text-start font-weight-normal">Багц цаг</th>
              <th class="text-start font-weight-normal">Дүнгийн схемийн нэр</th>
              <th class="text-start font-weight-normal">Дүнгийн схемийн код</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.index }}
              </td>
              <td>
                {{ item.COURSE_NAME }}
              </td>
              <td>
                {{ item.AVG_CONTACT_HOURS }}
              </td>
              <td>
                {{ item.COURSE_CONTACT_HOURS }}
              </td>
              <td>
                {{ item.GRADING_SCHEME_NAME }}
              </td>
              <td>
                {{ item.GRADING_SCHEME_ID }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee22DialogManager from "./ScoreInputUnelgee22DialogManager";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ScoreInputUnelgee22DialogManager,
  },
  data: () => ({
    halfYearDate: null,
    halfYear2Date: null,
    currentSelectedSemester: 2,
    coursesAdditional: null,
    school: null,
    showMyClassLesson: true,
    students: null,
    selectedProgram: null,
    teachers: null,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    lessons: null,
    loading: false,
    currentSelectedLesson: null,
    renderComponent: true,
    unelgeeDialog: false,
    savingUnelgee: false,
    simpleCalculationModel: true,
    allScores: null,
    selectedGradingMethod: 1,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр дүнгээр",
        value: 1,
      },
      // {
      //   name: "Сүүлийн шалгалтын дүнгээр",
      //   value: 2,
      // },
    ],
    unelgee1Scores: null,
    loadingInverval: null,
    selectedHalfYear: null,
    preText: null,
    savingGradeLoading: false,
    showGradesFromEsisDialog: false,
    lessonStudentScores: null,
    selectedEsisLesson: null,
    showLessonEsisDataDialog: null,
    coursesData: null,
    pullingCourseLoading: false,
    showCoursesEsisDataDialog: false,
  }),

  props: {
    zClassGroupRefPath: {
      type: String,
      required: true,
    },
    selectedHalfYearId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              list.push(parentLL);
            } else {
              if (cc.subLessons == undefined) cc.subLessons = [];
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
  },
  created() {
    this.halfYearDate = new Date(
      new Date().getFullYear() +
        "-" +
        this.$store.state.calendarButez2[2].months[0].name +
        "-" +
        this.$store.state.calendarButez2[2].months[0].days[0].day
    );
    this.halfYear2Date = new Date(
      new Date().getFullYear() +
        "-" +
        this.$store.state.calendarButez2[3].months[
          this.$store.state.calendarButez2[3].months.length - 1
        ].name +
        "-" +
        this.$store.state.calendarButez2[3].months[
          this.$store.state.calendarButez2[3].months.length - 1
        ].days[
          this.$store.state.calendarButez2[3].months[
            this.$store.state.calendarButez2[3].months.length - 1
          ].days.length - 1
        ].day
    );
    this.preText =
      this.userData.school.currentYear +
      "-" +
      this.userData.school.currentYear +
      1;
    this.selectedHalfYear = {
      yearId: Number(this.selectedHalfYearId),
      name: this.preText + " xичээлийн жил, 1-р xагас жил",
    };
    if (!this.school) {
      this.school = this.userData.school;
    }
    if (
      typeof this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
      "number"
    ) {
      this.selectedGradingMethod =
        this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE;
    }
    fb.db
      .doc(this.zClassGroupRefPath)
      .get()
      .then(async (doc) => {
        let prog = doc.data();
        prog.ref = doc.ref;
        prog.id = doc.id;
        await this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", Number(prog.STUDENT_GROUP_ID))
          .get()
          .then((docs) => {
            prog.students = [];
            docs.forEach((doc) => {
              let student = doc.data();
              student.ref = doc.ref;
              student.id = doc.id;
              if (this.selectedHalfYear.yearId == 1) {
                if (
                  !student.ACTION_DATE ||
                  new Date(student.ACTION_DATE) <= this.halfYearDate
                ) {
                  if (!student.moved) {
                    prog.students.push(student);
                  }
                }
              } else {
                if (
                  !student.ACTION_DATE ||
                  new Date(student.ACTION_DATE) <= this.halfYear2Date
                ) {
                  if (!student.moved) {
                    prog.students.push(student);
                  }
                }
              }
            });
          });
        this.selectedProgram = prog;
        this.readLessons();
      });
  },
  watch: {
    lessons(val, preVal) {
      if (!preVal || val.length != preVal.length) this.loading = true;
    },
    loading(val) {
      if (val) {
        if (this.loadingInverval) {
          clearInterval(this.loadingInverval);
        }
        this.loadingInverval = setInterval(() => {
          var allDone = true;
          const lsnLength = this.lessons.length;
          this.lessons.forEach((lesson) => {
            // if (!lesson["analyticsSaved-" + this.selectedHalfYear.yearId]) {
            if (!lesson.students && !lesson.unelgee2Scores) {
              allDone = false;
            }
            // }
          });
          setTimeout(() => {
            if (allDone && lsnLength == this.lessons.length) {
              this.loading = false;
              clearInterval(this.loadingInverval);
              setTimeout(() => {
                this.lessons = this.lessons
                  .sort(
                    (a, b) =>
                      a.courseInfo.SUBJECT_AREA_ID -
                      b.courseInfo.SUBJECT_AREA_ID
                  )
                  .sort(
                    (a, b) =>
                      b.courseInfo.ENROLLMENT_CATEGORY.length -
                      a.courseInfo.ENROLLMENT_CATEGORY.length
                  );
              }, 1000);
            } else {
              setTimeout(() => {
                this.loading = false;
                clearInterval(this.loadingInverval);
              }, 10000);
            }
          }, 1500);
        }, 1500);
      }
    },
  },
  methods: {
    findFaultyDate(item) {
      var tmp = item.unelgee2Scores.find((score) => {
        try {
          var date1 = score.approvedAt.toDate();
        } catch {
          date1 = score.approvedAt;
        }

        try {
          var date2 = item.finalGradeSavedToEsisAt.toDate();
        } catch {
          date2 = item.finalGradeSavedToEsisAt;
        }

        if (new Date(date1).getTime() > new Date(date2).getTime()) {
          return score;
        }
      });
      return tmp;
    },
    saveAllLessonScoresToESIS() {
      this.lessons.forEach((lesson) => {
        // console.log(lesson.courseInfo.COURSE_NAME, lesson.courseInfo.SUBJECT_AREA_ID)
        if (
          !(
            lesson.courseInfo.SUBJECT_AREA_ID == 233 &&
            lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY"
          ) &&
          // !lesson.finalGradeSavedToEsis &&
          Number(this.getAnalystics(lesson, 1)) ==
            Number(lesson.students.length)
        ) {
          this.saveSpecialLessonGrades(lesson, false);
          // console.log("dfsdf")
        }
      });
    },
    sendAllNull() {
      // this.$swal
      //   .fire({
      //     title:
      //       "Сонгоны хичээл сонгоогүй хүүхдүүд тэг оноотой хадгалагдсан гэсэн асуудал үүссэн бол тийм гэдэг товчийг дарна уу!",
      //     text: "Зөвхөн сонгон сонгосон сурагчдын дүн ЗӨВ хадгалагдсан бол ХАМААРАХГҮЙ! ҮГҮЙ товчийг дарна уу!",
      //     showCancelButton: true,
      //     confirmButtonText: "Тийм",
      //     cancelButtonText: "Үгүй",
      //     reverseButtons: true,
      //     customClass: {
      //       cancelButton: "swal2-cancel-button-custom",
      //       confirmButton: "swal2-ok-button-custom",
      //     },
      //   })
      //   .then((result) => {
      //     if (result.isConfirmed) {
      this.lessons.forEach(async (lesson) => {
        if (
          lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
          lesson.finalGradeSavedToEsis &&
          Number(this.getAnalystics(lesson, 1)) ==
            Number(lesson.students.length) &&
          lesson.students &&
          lesson.students.length != this.selectedProgram.students.length
        ) {
          await this.sendNull(lesson);
        }
      });
      // }
      // });
    },
    saveAllLessonsToESIS() {
      this.$swal
        .fire({
          title: "Ээлжит хичээлүүдийг үүсгэх үү?",
          showCancelButton: true,
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.lessons.forEach((lesson) => {
              if (!lesson.esisSavedLessonData) {
                this.saveEeljitLessonToEsis(lesson, true);
              }
            });
          }
        });
    },
    getAnalystics(lesson, mode) {
      let approvedNumber = 0;
      let rejectedNumber = 0;
      let inProgressNumber = 0;
      let sentNumber = 0;
      let scoreAverage = 0;

      if (lesson.unelgee2Scores && lesson.students) {
        lesson.unelgee2Scores.forEach((unelgee2) => {
          let foundStud;
          foundStud = lesson.students.find(
            (student) => unelgee2.PERSON_ID == student.PERSON_ID
          );
          if (foundStud) {
            if (unelgee2.approved == true) {
              if (typeof unelgee2.scoreFinal == "number")
                scoreAverage += unelgee2.scoreFinal;
              approvedNumber++;
            } else if (unelgee2.approved == false) {
              rejectedNumber++;
            } else if (unelgee2.sentToManager == true) {
              inProgressNumber++;
            } else if (
              unelgee2.sentToManager != true &&
              unelgee2.score != null &&
              unelgee2.score != undefined &&
              typeof unelgee2.score == "number"
            ) {
              sentNumber++;
            }
          }
        });
      }
      if (mode == 1) {
        return approvedNumber;
      } else if (mode == 2) {
        return inProgressNumber;
      } else if (mode == 3) {
        return rejectedNumber;
      } else if (mode == 4) {
        return sentNumber;
      } else if (mode == 5) {
        return !isNaN(scoreAverage / approvedNumber)
          ? scoreAverage / approvedNumber <= 1
            ? (scoreAverage / approvedNumber) * 100 + "%"
            : scoreAverage / approvedNumber == 100
            ? scoreAverage / approvedNumber + "%"
            : (scoreAverage / approvedNumber).toFixed(2) + "%"
          : "-";
      }
    },
    _getConfirmationProcent(item) {
      var xxx = this.getAnalystics(item, 1);
      var yyy = item.students.length;
      if (xxx && yyy) return (xxx / yyy) * 100;
      return 0;
    },
    _print3() {
      console.log(this.currentSelectedLesson);
    },

    async _doUnelgee(lesson) {
      this.currentSelectedLesson = lesson;
      this.currentSelectedLesson.students.sort((a, b) =>
        a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
      );
      this.currentSelectedLesson.loading2 = true;
      await this.userData.school.ref
        .collection("courses-" + this.userData.school.currentYear)
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores")
        .where("STUDENT_GROUP_ID", "==", this.selectedProgram.STUDENT_GROUP_ID)
        .onSnapshot((querySnapshot) => {
          this.currentSelectedLesson.unelgee1Scores = [];
          var counter = 0;
          if (querySnapshot.size == 0) {
            this.unelgeeDialog = !this.unelgeeDialog;
          }
          querySnapshot.forEach(async (doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            await item.examRef.get().then((doc) => {
              if (doc.exists && doc.data().deleted != true) {
                item.examDate = new Date(doc.data().examDate);
                item.deleted = false;
              } else {
                item.deleted = true;
              }
            });
            if (item.deleted == false) {
              this.currentSelectedLesson.unelgee1Scores.push(item);
            }
            counter++;
            if (counter == querySnapshot.size) {
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            }
          });
        });
    },
    _saveUnelgee2() {
      var batch = fb.db.batch();
      this.savingUnelgee = true;
      var scoreLevelStrings = {
        _I: 0,
        _II: 0,
        _III: 0,
        _IV: 0,
        _V: 0,
        _VI: 0,
        _VII: 0,
        _VIII: 0,
      };
      var scoreLevelStrings2 = {
        passed: 0,
        failed: 0,
      };
      var allCompleted = true;

      for (var score of this.currentSelectedLesson.unelgee2Scores) {
        if (
          score.scoreFinal != undefined &&
          score.scoreFinal != null &&
          typeof score.scoreFinal == "number" &&
          score.approved != null
        ) {
          score["approvedAt"] = new Date();
          score["approvedByRef"] = this.userData.ref;
          score["approvedByEmail"] = this.userData.email;
          if (score.approved == false) {
            score["rejected"] = true;
          }

          batch.update(score.ref, score);

          if (score.approved == true) {
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (score.scoreFinal > 0) {
                scoreLevelStrings2["passed"]++;
              } else {
                scoreLevelStrings2["failed"]++;
              }
            } else {
              if (score.scoreLevelNumber == 1) {
                scoreLevelStrings["_I"]++;
              } else if (score.scoreLevelNumber == 2) {
                scoreLevelStrings["_II"]++;
              } else if (score.scoreLevelNumber == 3) {
                scoreLevelStrings["_III"]++;
              } else if (score.scoreLevelNumber == 4) {
                scoreLevelStrings["_IV"]++;
              } else if (score.scoreLevelNumber == 5) {
                scoreLevelStrings["_V"]++;
              } else if (score.scoreLevelNumber == 6) {
                scoreLevelStrings["_VI"]++;
              } else if (score.scoreLevelNumber == 7) {
                scoreLevelStrings["_VII"]++;
              } else if (score.scoreLevelNumber == 8) {
                scoreLevelStrings["_VIII"]++;
              }
            }
          } else {
            allCompleted = false;
          }
        }
      }

      var tmp = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
        approvedNumber: 0,
        inProgressNumber: 0,
        rejectedNumber: 0,
        sentNumber: 0,
        scoreAverage: 0,
      };
      this.currentSelectedLesson.byTeachers.forEach((teacher) => {
        tmp[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            teacher.teacherId
        ] = {
          approvedNumber: 0,
          inProgressNumber: 0,
          rejectedNumber: 0,
          sentNumber: 0,
          scoreAverage: 0,
        };
      });
      // go through students and finalizing the inProgress, sentNumber, approvedNumber, etc.
      for (var student of this.currentSelectedLesson.students) {
        if (this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1) {
          var studentScore = this.currentSelectedLesson.unelgee2Scores.find(
            (studentScore) =>
              studentScore.PERSON_ID == student.PERSON_ID &&
              studentScore.lessonGroupId == student.lessonGroup.id
          );
        } else {
          studentScore = this.currentSelectedLesson.unelgee2Scores.find(
            (studentScore) => studentScore.PERSON_ID == student.PERSON_ID
          );
        }
        if (
          studentScore &&
          this.currentSelectedLesson.byTeachers.find(
            (teacher) => teacher.teacherRef == studentScore.gradedBy.ref
          ) == undefined
        ) {
          if (this.currentSelectedLesson.byTeachers.length == 1) {
            studentScore.gradedBy.PERSON_ID =
              this.currentSelectedLesson.byTeachers[0].teacherId;
          } else if (this.currentSelectedLesson.byTeachers.length > 1) {
            if (
              !tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  studentScore.gradedBy.PERSON_ID
              ]
            ) {
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  studentScore.gradedBy.PERSON_ID
              ] = {
                approvedNumber: 0,
                inProgressNumber: 0,
                rejectedNumber: 0,
                sentNumber: 0,
                scoreAverage: 0,
              };
            }
          }
        }
        if (studentScore) {
          //if score is approved by manager, we add them
          if (studentScore.approved == true) {
            tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .approvedNumber++;
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                studentScore.gradedBy.PERSON_ID
            ].approvedNumber++;
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (studentScore.score > 0) {
                tmp[
                  "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage =
                  tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                    .scoreAverage + 100;
                tmp[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    studentScore.gradedBy.PERSON_ID
                ].scoreAverage =
                  tmp[
                    "year" +
                      this.selectedHalfYear.yearId +
                      "UnelgeeScores-" +
                      studentScore.gradedBy.PERSON_ID
                  ].scoreAverage + 100;
              } else {
                tmp[
                  "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage =
                  tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                    .scoreAverage + studentScore.score;
                tmp[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    studentScore.gradedBy.PERSON_ID
                ].scoreAverage =
                  tmp[
                    "year" +
                      this.selectedHalfYear.yearId +
                      "UnelgeeScores-" +
                      studentScore.gradedBy.PERSON_ID
                  ].scoreAverage + studentScore.score;
              }
            } else {
              //baga angias deesh dungeer toozdog bol
              tmp[
                "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
              ].scoreAverage =
                tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                  .scoreAverage + studentScore.score;
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  studentScore.gradedBy.PERSON_ID
              ].scoreAverage =
                tmp[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    studentScore.gradedBy.PERSON_ID
                ].scoreAverage + studentScore.score;
            }
          } else if (studentScore.approved == false) {
            tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .rejectedNumber++;
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                studentScore.gradedBy.PERSON_ID
            ].rejectedNumber++;
          } else if (
            (studentScore.approved == undefined ||
              studentScore.approved == null) &&
            studentScore.sentToManager == true
          ) {
            tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .inProgressNumber++;
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                studentScore.gradedBy.PERSON_ID
            ].inProgressNumber++;
          } else if (
            studentScore.sentToManager != true &&
            studentScore.score != null &&
            studentScore.score != undefined &&
            typeof studentScore.score == "number"
          ) {
            tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .sentNumber++;
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                studentScore.gradedBy.PERSON_ID
            ].sentNumber++;
          }
        }
      }

      tmp[
        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      ].scoreAverage = Number(
        (
          tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .scoreAverage /
          tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber
        ).toFixed(1)
      );

      this.currentSelectedLesson.byTeachers.forEach((teacher) => {
        tmp[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            teacher.teacherId
        ].scoreAverage = Number(
          (
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                teacher.teacherId
            ].scoreAverage /
            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                teacher.teacherId
            ].approvedNumber
          ).toFixed(1)
        );
      });

      batch.set(this.currentSelectedLesson.ref, tmp, {
        merge: true,
      });
      if (
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
      ) {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(
              this.selectedHalfYear.yearId > 1
                ? this.currentSelectedLesson.id +
                    "-" +
                    this.selectedHalfYear.yearId
                : this.currentSelectedLesson.id
            ),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,
            passed: scoreLevelStrings2["passed"],
            failed: scoreLevelStrings2["failed"],
          },
          { merge: true }
        );
      } else {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(
              this.selectedHalfYear.yearId > 1
                ? this.currentSelectedLesson.id +
                    "-" +
                    this.selectedHalfYear.yearId
                : this.currentSelectedLesson.id
            ),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,
            _I: scoreLevelStrings["_I"],
            _II: scoreLevelStrings["_II"],
            _III: scoreLevelStrings["_III"],
            _IV: scoreLevelStrings["_IV"],
            _V: scoreLevelStrings["_V"],
            _VI: scoreLevelStrings["_VI"],
            _VII: scoreLevelStrings["_VII"],
            _VIII: scoreLevelStrings["_VIII"],
          },
          { merge: true }
        );
      }

      batch.commit().then(async () => {
        if (allCompleted) {
          //callxxx
        }
        var lesson = Object.assign(
          {},
          this.filteredLessons.find(
            (lsn) => lsn.id == this.currentSelectedLesson.id
          )
        );
        this.handleTeacherAndClassGroupAnalytics();
        await this.saveSpecialLessonGrades(lesson, true);
        this.savingUnelgee = false;
        this.$forceUpdate();
        this._closeUnelgeeDialog();
      });
    },
    async handleTeacherAndClassGroupAnalytics(runTeacher) {
      if (!runTeacher) {
        const headers = {
          "Content-Type": "application/json",
        };
        var requestBody = {
          teacherRef: this.currentSelectedLesson.byTeachers[0].teacherRef.path,
          schoolRef: this.userData.school.ref.path,
          schoolYear: this.userData.school.currentYear,
          selectedHalfYear: this.selectedHalfYear,
          halfYearDate: this.halfYearDate,
          halfYear2Date: this.halfYear2Date,
        };
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/teacherUnelgee2Analytics",
            requestBody,
            headers
          )
          .then((response) => {
            console.log(response);
          });
      }

      var classGroupAnalytics = {
        approvedNumber: 0,
        rejectedNumber: 0,
        inProgressNumber: 0,
        sentNumber: 0,
        scoreAverage: 0,
        totalLessonStudentsNumber: 0,
        electiveLessonNumber: 0,
        mandatoryLessonNumber: 0,
      };

      for (var lesson of this.lessons) {
        await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .get()
          .then((docs) => {
            console.log(docs.size);
            lesson.unelgee2Scores = [];
            docs.forEach((doc) => {
              let studentScore = doc.data();
              studentScore.ref = doc.ref;
              studentScore.id = doc.id;
              if (
                lesson.students.find(
                  (stud) => stud.PERSON_ID == studentScore.PERSON_ID
                )
              )
                if (lesson.esisLessonType.esisLessonTypeId > 1) {
                  if (
                    lesson.lessonGroups.find(
                      (lg) => lg.id == studentScore.lessonGroupId
                    ) &&
                    lesson.lessonGroups.find(
                      (lg) => lg.id == studentScore.lessonGroupId
                    ).students &&
                    lesson.lessonGroups
                      .find((lg) => lg.id == studentScore.lessonGroupId)
                      .students.find(
                        (stud) => stud.PERSON_ID == studentScore.PERSON_ID
                      )
                  ) {
                    lesson.unelgee2Scores.push(studentScore);
                  }
                } else {
                  if (!studentScore.lessonGroupId)
                    lesson.unelgee2Scores.push(studentScore);
                }
            });
            if (lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
              classGroupAnalytics.electiveLessonNumber++;
            } else if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
              classGroupAnalytics.mandatoryLessonNumber++;
            }
            console.log(lesson.students.length);
            classGroupAnalytics.totalLessonStudentsNumber +=
              lesson.students.length;

            for (var scoreUnelgee of lesson.unelgee2Scores) {
              if (scoreUnelgee.approved == true) {
                if (typeof scoreUnelgee.score == "number") {
                  if (scoreUnelgee.score <= 1) {
                    classGroupAnalytics.scoreAverage =
                      classGroupAnalytics.scoreAverage + 100;
                  } else {
                    classGroupAnalytics.scoreAverage =
                      classGroupAnalytics.scoreAverage + scoreUnelgee.score;
                  }
                }

                classGroupAnalytics.approvedNumber++;
              } else if (scoreUnelgee.approved == false) {
                classGroupAnalytics.rejectedNumber++;
              } else if (scoreUnelgee.sentToManager == true) {
                classGroupAnalytics.inProgressNumber++;
              } else if (
                scoreUnelgee.sentToManager != true &&
                scoreUnelgee.score != null &&
                scoreUnelgee.score != undefined &&
                typeof scoreUnelgee.score == "number"
              ) {
                classGroupAnalytics.sentNumber++;
              }
            }
          });
      }
      classGroupAnalytics.scoreAverage = (
        classGroupAnalytics.scoreAverage / classGroupAnalytics.approvedNumber
      ).toFixed(2);
      var tmp = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ] = classGroupAnalytics;
      this.selectedProgram.ref.update(tmp).then(() => {
        console.log("classGroup done " + this.selectedProgram.ref.path);
      });
    },
    _closeUnelgeeDialog() {
      this.currentSelectedLesson.loading2 = false;
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _print2() {
      console.log(this.selectedProgram.ref.path);
    },
    async getUnelgeeData(lesson) {
      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        )
        .collection("scores")
        .onSnapshot((docs) => {
          lesson.unelgee2Scores = [];
          docs.forEach((doc) => {
            let studentScore = doc.data();
            studentScore.id = doc.id;
            studentScore.ref = doc.ref;
            if (
              lesson.students.find(
                (stud) => stud.PERSON_ID == studentScore.PERSON_ID
              )
            ) {
              if (lesson.esisLessonType.esisLessonTypeId > 1) {
                if (
                  lesson.lessonGroups.find(
                    (lg) => lg.id == studentScore.lessonGroupId
                  ) &&
                  lesson.lessonGroups.find(
                    (lg) => lg.id == studentScore.lessonGroupId
                  ).students &&
                  lesson.lessonGroups
                    .find((lg) => lg.id == studentScore.lessonGroupId)
                    .students.find(
                      (stud) => stud.PERSON_ID == studentScore.PERSON_ID
                    )
                ) {
                  lesson.unelgee2Scores.push(studentScore);
                }
              } else {
                if (!studentScore.lessonGroupId)
                  lesson.unelgee2Scores.push(studentScore);
              }
            }
          });
          this.$forceUpdate();
        });
    },

    async _findGroupStudents(lesson) {
      var allStudents = [];
      lesson.lessonGroups = [];
      var lessonGroups = await lesson.ref
        .collection("lesson-groups")
        .orderBy("groupIndex", "asc")
        .get()
        .then((docs) => {
          let list = [];
          docs.forEach((doc) => {
            let lg = doc.data();
            lg.ref = doc.ref;
            lg.id = doc.id;
            if (lg.teacher) {
              list.push(lg);
            }
          });
          return list;
        });
      for (var lg of lessonGroups) {
        lesson.lessonGroups.push(lg);
        lg.students = [];
        if (lg.allStudentsSelected) {
          this.selectedProgram.students.forEach((studentData) => {
            let student = Object.assign({}, studentData);
            student.lessonGroup = lg;
            lg.students.push(student);
            allStudents.push(student);
          });
        } else {
          await lg.ref
            .collection("students")
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                student.lessonGroup = lg;

                var foundStudent = this.selectedProgram.students.find(
                  (stud) => stud.PERSON_ID == student.PERSON_ID
                );
                if (foundStudent) {
                  if (this.selectedHalfYear.yearId == 1) {
                    if (
                      !foundStudent.ACTION_DATE ||
                      new Date(foundStudent.ACTION_DATE) <= this.halfYearDate
                    ) {
                      if (!student.moved) {
                        allStudents.push(student);
                        lg.students.push(student);
                      }
                    }
                  } else {
                    if (
                      !foundStudent.ACTION_DATE ||
                      new Date(foundStudent.ACTION_DATE) <= this.halfYear2Date
                    ) {
                      if (!student.moved) {
                        allStudents.push(student);
                        lg.students.push(student);
                      }
                    }
                  }
                }
              });
            });
        }
      }

      lesson.students = allStudents;
      this.$forceUpdate();
    },
    async saveSpecialLessonGrades(lesson, openDialog) {
      console.log("XXXX");
      if (
        lesson.courseInfo.SUBJECT_AREA_ID == 233 &&
        lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY"
      ) {
        var gradingMethodsHtml = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
        for (var gradingMethod of this.gradingMethods) {
          if (this.selectedGradingMethod == gradingMethod.value) {
            gradingMethodsHtml =
              gradingMethodsHtml +
              `<option selected value="${gradingMethod.value}">${gradingMethod.name}</option>`;
          } else {
            gradingMethodsHtml =
              gradingMethodsHtml +
              `<option value="${gradingMethod.value}">${gradingMethod.name}</option>`;
          }
        }

        gradingMethodsHtml = gradingMethodsHtml + "</select>";

        this.$swal
          .fire({
            title: "Жилийн эцсийн дүн бодох форматаа сонгоно уу!",
            text: "",
            html: gradingMethodsHtml,
            showCancelButton: true,
            confirmButtonText: "Xадгалаx",
            cancelButtonText: "Цуцлаx",
            reverseButtons: true,
            preConfirm: () => {
              const selectValue = document.getElementById("select-input").value;
              return selectValue;
            },
            customClass: {
              cancelButton: "swal2-cancel-button-custom",
              confirmButton: "swal2-ok-button-custom",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.userData.school.ref.update({
                  GRADING_METHOD_USED_FOR_FINAL_GRADE: Number(result.value),
                });
                lesson.gradingMethod = Number(result.value);
                await this.saveGradesToEsis(lesson, openDialog);
              }
            }
          });
      } else if (
        !this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE &&
        lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
      ) {
        var AVG_HOURS = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
        var AVG_HOURS_HTML = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
        for (var hours of AVG_HOURS) {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML + `<option value="${hours}">${hours}</option>`;
        }

        AVG_HOURS_HTML = AVG_HOURS_HTML + "</select>";

        this.$swal
          .fire({
            title: "Долоо хоногт орох дундаж ЦАГАА сонгоно уу!",
            text: "",
            html: AVG_HOURS_HTML,
            showCancelButton: true,
            confirmButtonText: "Xадгалаx",
            cancelButtonText: "Цуцлаx",
            reverseButtons: true,
            preConfirm: () => {
              const selectValue = document.getElementById("select-input").value;
              return selectValue;
            },
            customClass: {
              cancelButton: "swal2-cancel-button-custom",
              confirmButton: "swal2-ok-button-custom",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.selectedProgram.ref.update({
                  AVG_CONTACT_HOURS_USED_FOR_ELECTIVE: Number(result.value),
                });
                this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE =
                  Number(result.value);
                this.$forceUpdate();

                await this.saveGradesToEsis(lesson, openDialog);
              }
            }
          });
      } else {
        await this.saveGradesToEsis(lesson, openDialog);
      }
    },
    updateClassAVG() {
      var AVG_HOURS = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
      var AVG_HOURS_HTML = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
      for (var hours of AVG_HOURS) {
        if (hours == this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE) {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML +
            `<option selected value="${hours}">${hours}</option>`;
        } else {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML + `<option value="${hours}">${hours}</option>`;
        }
      }

      AVG_HOURS_HTML = AVG_HOURS_HTML + "</select>";

      this.$swal
        .fire({
          title: "Долоо хоногт орох дундаж ЦАГАА сонгоно уу!",
          text: "",
          html: AVG_HOURS_HTML,
          showCancelButton: true,
          confirmButtonText: "Xадгалаx",
          cancelButtonText: "Цуцлаx",
          reverseButtons: true,
          preConfirm: () => {
            const selectValue = document.getElementById("select-input").value;
            return selectValue;
          },
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              this.selectedProgram.ref.update({
                AVG_CONTACT_HOURS_USED_FOR_ELECTIVE: Number(result.value),
              });
              this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE = Number(
                result.value
              );
              this.$forceUpdate();
            }
          }
        });
    },
    async showingEsisSavedScores(lesson) {
      this.currentSelectedLesson = lesson;
      lesson.scoresShowingLoading = true;
      this.$forceUpdate();
      let tries = 10;
      var token = false;
      while (token == false && tries > 0) {
        token = await axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              return res.data.token;
            } else {
              return false;
            }
          });
        tries--;
      }

      if (!token) {
        this.$swal.fire(
          "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
        );
        lesson.scoresShowingLoading = false;
        return false;
      }

      const headers = {
        "Content-Type": "application/json",
      };
      if (!lesson.xProgramPlanId) {
        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: token,
        };

        try {
          var response2 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling students failed");
          lesson.scoresShowingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }

        var programPlanId = null;
        if (response2.status == true) {
          var notEqualProgramPlanId = false;
          response2.students.forEach((student) => {
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            lesson.scoresShowingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            lesson.scoresShowingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            return false;
          }
        }
        console.log(response2, programPlanId);
        console.log(this.selectedProgram);
        var courseBody = {
          program: {
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
          },
          programPlanId: programPlanId,
          token: token,
        };
        try {
          var response3 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
              courseBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling courses failed");
          lesson.scoresShowingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        console.log(response3);
        if (response3.status == false) {
          console.log("pulling courses failed, " + response3.reason);
          lesson.scoresShowingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        if (response3.status == true) {
          if (response3.courses && response3.courses.length > 0) {
            lesson.courseData = response3.courses.find(
              (course) => course.COURSE_ID == lesson.courseInfo.COURSE_ID
            );
            var coursePlan = await this.userData.school.ref
              .collection("schoolPrograms")
              .doc(String(this.selectedProgram.PROGRAM_OF_STUDY_ID))
              .collection("stages")
              .doc(String(this.selectedProgram.PROGRAM_STAGE_ID))
              .collection("plans")
              .doc(String(programPlanId))
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let coursePlan = doc.data();
                  coursePlan.ref = doc.ref;
                  coursePlan.id = doc.id;
                  return coursePlan;
                } else {
                  return {
                    PROGRAM_PLAN_NAME: "-",
                  };
                }
              });
            if (!lesson.courseData) {
              console.log("course missing");
              lesson.scoresShowingLoading = false;
              this.$swal.fire(
                "ESIS алдаа гарлаа." +
                  coursePlan.PROGRAM_PLAN_NAME +
                  " дугаартай хөтөлбөр дээр энэ хичээл нэмэгдэггүй байх магагдлалтай. Та хичээлийн хөтөлбөрдөө байгааг шалгаад байхгүй бол оруулаад дахин оролдоорой."
              );
              return false;
            }
          }
        }
        lesson.ref.update({
          xProgramPlanId: lesson.courseData.PROGRAM_ELEMENT_ID,
        });
      }
      console.log(
        this.selectedProgram.STUDENT_GROUP_ID,
        lesson.xProgramPlanId,
        "xxx",
        token
      );
      try {
        await axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getFinalGradesFromEsis",
            {
              token: token,
              studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
              programElementId: lesson.xProgramPlanId
                ? lesson.xProgramPlanId
                : lesson.courseData.PROGRAM_ELEMENT_ID,
            },
            headers
          )
          .then((res) => {
            console.log(res);
            if (res.status === 200 && res.data.response.SUCCESS_CODE === 200) {
              this.lessonStudentScores = [];
              res.data.response.RESULT.forEach((stud) => {
                stud.FIRST_NAME = this.currentSelectedLesson.students.find(
                  (student) => student.PERSON_ID == stud.PERSON_ID
                ).FIRST_NAME;

                stud.LAST_NAME = this.currentSelectedLesson.students.find(
                  (student) => student.PERSON_ID == stud.PERSON_ID
                ).LAST_NAME;

                stud.STUDENT_GROUP_NAME =
                  this.currentSelectedLesson.students.find(
                    (student) => student.PERSON_ID == stud.PERSON_ID
                  ).STUDENT_GROUP_NAME;
                this.lessonStudentScores.push(stud);
              });
              this.lessonStudentScores.sort((a, b) =>
                a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
              );
            } else {
              this.$swal.fire(
                "ESIS алдаа гарлаа. " +
                  res.data.response.RESPONSE_MESSAGE +
                  " .Та хүлээж байгаад дахин оролдоно уу!"
              );
            }
            lesson.scoresShowingLoading = false;
            this.showGradesFromEsisDialog = true;
            this.$forceUpdate();
            this.forceRerender();
          });
      } catch (e) {
        console.log(e);
        this.$swal.fire(
          "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
        );
        lesson.scoresShowingLoading = false;

        return false;
      }
    },
    sendNull2(lesson) {
      this.$swal
        .fire({
          title:
            "Сонгоны хичээл сонгоогүй хүүхдүүд тэг оноотой хадгалагдсан гэсэн асуудал үүссэн бол тийм гэдэг товчийг дарна уу!",
          text: "Зөвхөн сонгон сонгосон сурагчдын дүн ЗӨВ хадгалагдсан бол ХАМААРАХГҮЙ! ҮГҮЙ товчийг дарна уу!",
          showCancelButton: true,
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.sendNull(lesson);
          }
        });
    },
    async sendNull(lesson) {
      if (!lesson.nullSendingLoading) {
        lesson.nullSendingLoading = true;
        this.$forceUpdate();
        let tries = 10;
        var token = false;

        try {
          while (token == false && tries > 0) {
            token = await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.userData.school._esisUserName,
                  password: this.userData.school._esisPword,
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  return res.data.token;
                } else {
                  return false;
                }
              });
            tries--;
          }
        } catch (e) {
          lesson.nullSendingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }

        if (!token) {
          lesson.nullSendingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }
        console.log(token);

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: token,
        };

        try {
          var response2 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling students failed");
          lesson.nullSendingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response2.status == true) {
          var notEqualProgramPlanId = false;
          response2.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            lesson.nullSendingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );

            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            lesson.nullSendingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );

            return false;
          }
        }
        console.log(response2);
        console.log(
          "PROGRAM_OF_STUDY_ID:" +
            PROGRAM_OF_STUDY_ID +
            ", " +
            "PROGRAM_STAGE_ID:" +
            PROGRAM_STAGE_ID +
            ", PROGRAM_PLAN_ID: " +
            programPlanId
        );
        console.log(this.selectedProgram);
        var courseBody = {
          program: {
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
          },
          programPlanId: programPlanId,
          token: token,
        };
        try {
          var response3 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
              courseBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling courses failed");
          lesson.nullSendingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        console.log(response3);
        if (response3.status == false) {
          console.log("pulling courses failed, " + response3.reason);
          lesson.nullSendingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        if (response3.status == true) {
          if (response3.courses && response3.courses.length > 0) {
            lesson.courseData = response3.courses.find(
              (course) =>
                course.COURSE_ID == lesson.courseInfo.COURSE_ID ||
                (course.SUBJECT_AREA_ID == lesson.courseInfo.SUBJECT_AREA_ID &&
                  lesson.courseInfo.COURSE_NAME.includes(
                    course.COURSE_CLASSIFICATION_NAME.replace("хичээл", "")
                      .replace("судлах", "")
                      .trim()
                  ))
            );

            console.log(lesson.courseData);
            var coursePlan = await this.userData.school.ref
              .collection("schoolPrograms")
              .doc(String(this.selectedProgram.PROGRAM_OF_STUDY_ID))
              .collection("stages")
              .doc(String(this.selectedProgram.PROGRAM_STAGE_ID))
              .collection("plans")
              .doc(String(programPlanId))
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let coursePlan = doc.data();
                  coursePlan.ref = doc.ref;
                  coursePlan.id = doc.id;
                  return coursePlan;
                } else {
                  return {
                    PROGRAM_PLAN_NAME: "-",
                  };
                }
              });
            if (!lesson.courseData) {
              console.log("course missing");
              lesson.nullSendingLoading = false;
              this.$swal.fire(
                "ESIS алдаа гарлаа." +
                  coursePlan.PROGRAM_PLAN_NAME +
                  " дугаартай хөтөлбөр дээр энэ хичээл нэмэгдэггүй байх магагдлалтай. Та хичээлийн хөтөлбөрдөө байгааг шалгаад байхгүй бол оруулаад дахин оролдоорой."
              );
              return false;
            }
          }
        }
        // var requestBody = {
        //   GRADING_SCHEME_ID: lesson.courseData.GRADING_SCHEME_ID,
        //   token: token,
        // };

        // lesson.gradingPlans = await axios
        //   .post(
        //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/gradeIdRetriever",
        //     requestBody,
        //     headers
        //   )
        //   .then((response) => {
        //     if (response.status == 200) {
        //       return response.data.RESULT;
        //     } else {
        //       return null;
        //     }
        //   });

        var tmp = {
          lessonRef: lesson.ref,
          programRef: this.selectedProgram.ref,
          package: {
            programElementId: Number(lesson.courseData.PROGRAM_ELEMENT_ID),
            studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
            academicLevel: Number(this.selectedProgram.ACADEMIC_LEVEL),
            event: "ADD",
            courseGradeArray: [],
          },
          isNull: true,
        };

        // var emptyGradeFormat = false;
        response2.students
          .filter(
            (stud) =>
              !lesson.students.find(
                (student) => Number(student.PERSON_ID) == Number(stud.PERSON_ID)
              )
          )
          .forEach((student) => {
            // let gradeFormat = lesson.gradingPlans.find(
            //   (grade) =>
            //     grade.MAX_GRADE_MARK >=
            //       Number(student.unelgeeScore.scoreFinal) &&
            //     grade.MIN_GRADE_MARK < Number(student.unelgeeScore.scoreFinal)
            // );
            // if (gradeFormat) {
            tmp.package.courseGradeArray.push({
              PERSON_ID: Number(student.PERSON_ID),
              GRADE_MARK: null,
              GRADE_ID: null,
              GRADE_CODE: null,
              GRADE_POINTS: null,
              GRADE_OUTCOME: null,
              AVG_CONTACT_HOURS: null,
            });
            // } else {
            //   emptyGradeFormat = true;
            // }
          });
        // if (emptyGradeFormat) {
        //   lesson.nullSendingLoading = false;
        //   this.$swal.fire(
        //     "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
        //   );
        //   console.log(emptyGradeFormat, "empty", lesson.gradingPlans);
        //   return false;
        // }
        console.log(tmp, lesson.unelgee2Scores);
        console.log(tmp.package, "GRADES POSTMAN HERE");
        if (tmp.package.courseGradeArray.length > 0) {
          try {
            await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/sendFinalGradesToEsis",
                {
                  token: token,
                  grades: tmp.package,
                  isNull: {
                    lessonRef: lesson.ref.path,
                  },
                },
                headers
              )
              .then((res) => {
                console.log(res);
                if (res.status === 200 && res.data.status === 200) {
                  lesson.finalEsisGradeExtraStudentsFixed = true;
                  this.selectedProgram["savedNULLGradesToEsis"] = {
                    successfulLessonCounter: 0,
                    totalLessonCounter: 0,
                  };
                  this.filteredLessons
                    .filter(
                      (lsn) =>
                        lsn.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
                        lsn.finalGradeSavedToEsis &&
                        lsn.students.length !=
                          this.selectedProgram.students.length
                    )
                    .forEach((lsn) => {
                      if (lsn.finalEsisGradeExtraStudentsFixed) {
                        this.selectedProgram["savedNULLGradesToEsis"]
                          .successfulLessonCounter++;
                      }
                      this.selectedProgram["savedNULLGradesToEsis"]
                        .totalLessonCounter++;
                    });

                  this.selectedProgram.ref
                    .update({
                      savedNULLGradesToEsis:
                        this.selectedProgram["savedNULLGradesToEsis"],
                    })
                    .then(() => {
                      console.log(
                        this.selectedProgram["savedNULLGradesToEsis"]
                      );
                      lesson.nullSendingLoading = false;
                      this.$forceUpdate();
                    });
                }
              });
          } catch (e) {
            lesson.nullSendingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            console.log(lesson, tmp, "ERROR HERE");
            console.log(e, "ERROR HERE");
          }
        } else {
          lesson.nullSendingLoading = false;
        }
      }
      this.$forceUpdate();
    },
    async getCourseDatas() {
      if (!this.pullingCourseLoading) {
        this.pullingCourseLoading = true;
        this.$forceUpdate();
        var token = false;
        var tries = 10;
        try {
          while (token == false && tries > 0) {
            token = await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.userData.school._esisUserName,
                  password: this.userData.school._esisPword,
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  return res.data.token;
                } else {
                  return false;
                }
              });
            tries--;
          }
        } catch (e) {
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }

        if (!token) {
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }
        console.log(token);

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: token,
        };

        try {
          var response2 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling students failed");
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }
        console.log(response2);

        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response2.status == true) {
          var notEqualProgramPlanId = false;
          response2.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            this.pullingCourseLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            this.$forceUpdate();

            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            this.pullingCourseLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            this.$forceUpdate();

            return false;
          }
        }

        var courseBody = {
          program: {
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
          },
          programPlanId: programPlanId,
          token: token,
        };
        try {
          var response3 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
              courseBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling courses failed");
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        console.log(response3);
        if (response3.status == false) {
          console.log("pulling courses failed, " + response3.reason);
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        this.coursesData = {};
        await this.userData.school.ref
          .collection("schoolPrograms")
          .doc(String(PROGRAM_OF_STUDY_ID))
          .collection("stages")
          .doc(String(PROGRAM_STAGE_ID))
          .collection("plans")
          .doc(String(programPlanId))
          .get()
          .then((doc) => {
            if (doc.exists) {
              let programPlan = doc.data();
              programPlan.ref = doc.ref;
              programPlan.id = doc.id;
              this.coursesData.programPlan = programPlan;
            }
          });
        var counter = 0;
        this.coursesData.courses = [];
        response3.courses.forEach((course) => {
          counter++;
          course.index = counter;
          this.coursesData.courses.push(course);
        });
        this.pullingCourseLoading = false;
        this.$forceUpdate();
        this.showCoursesEsisDataDialog = true;
      }
    },
    async getLessonData(lesson) {
      if (!lesson.lessonSavingLoading) {
        lesson.lessonSavingLoading = true;
        this.$forceUpdate();
        var token = false;
        var tries = 10;
        try {
          while (token == false && tries > 0) {
            token = await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.userData.school._esisUserName,
                  password: this.userData.school._esisPword,
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  return res.data.token;
                } else {
                  return false;
                }
              });
            tries--;
          }
        } catch (e) {
          lesson.lessonSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }

        if (!token) {
          lesson.lessonSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }
        console.log(token);

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: token,
        };

        try {
          var response2 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling students failed");
          lesson.lessonSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();

          return false;
        }
        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response2.status == true) {
          var notEqualProgramPlanId = false;
          response2.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            lesson.lessonSavingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            this.$forceUpdate();

            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            lesson.lessonSavingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            this.$forceUpdate();

            return false;
          }
        }

        lesson.esisSavedLessonData.PROGRAM_STAGE_ID = PROGRAM_STAGE_ID;
        lesson.esisSavedLessonData.PROGRAM_PLAN_ID = programPlanId;
        lesson.esisSavedLessonData.PROGRAM_OF_STUDY_ID = PROGRAM_OF_STUDY_ID;
        await this.userData.school.ref
          .collection("schoolPrograms")
          .doc(String(PROGRAM_OF_STUDY_ID))
          .collection("stages")
          .doc(String(PROGRAM_STAGE_ID))
          .collection("plans")
          .doc(String(programPlanId))
          .get()
          .then((doc) => {
            if (doc.exists) {
              lesson.esisSavedLessonData.programPlan = doc.data();
            }
          });
        lesson.lessonSavingLoading = false;
        this.$forceUpdate();
        var counter = 1;
        lesson.byTeachers.forEach((teacher) => {
          if (lesson.esisSavedLessonData[teacher.teacherId]) {
            teacher.index = counter++;
          }
        });
        this.selectedEsisLesson = lesson;
        this.showLessonEsisDataDialog = true;
      }
    },
    async saveEeljitLessonToEsis(lesson, bulkSave) {
      var byTeachers = [];
      for (var teacher of lesson.byTeachers) {
        var teacherExists = true;
        if (lesson.byTeachers.length > 1) {
          teacherExists = await lesson.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", teacher.teacherId)
            .get()
            .then((docs) => {
              if (docs.size == 0) {
                return false;
              }
              return true;
            });
        }
        if (teacher && teacher.teacherId && teacherExists) {
          byTeachers.push(teacher.teacherId);
        }
      }
      console.log(lesson.byTeachers, byTeachers);
      if (bulkSave) {
        if (!lesson.lessonSavingLoading) {
          lesson.lessonSavingLoading = true;
          this.$forceUpdate();
          var token = false;
          var tries = 10;
          try {
            while (token == false && tries > 0) {
              token = await axios
                .post(
                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                  {
                    username: this.userData.school._esisUserName,
                    password: this.userData.school._esisPword,
                  }
                )
                .then((res) => {
                  if (res.status === 200) {
                    return res.data.token;
                  } else {
                    return false;
                  }
                });
              tries--;
            }
          } catch (e) {
            lesson.lessonSavingLoading = false;
            this.$forceUpdate();
            this.$swal.fire(
              "(" +
                lesson.courseInfo.COURSE_NAME +
                ") ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            return false;
          }
          const headers = {
            "Content-Type": "application/json",
          };

          var requestBody = {
            lesson: {
              refPath: lesson.ref.path,
              id: lesson.id,
              courseInfo: {
                ENROLLMENT_CATEGORY: lesson.courseInfo.ENROLLMENT_CATEGORY,
                SUBJECT_AREA_ID: lesson.courseInfo.SUBJECT_AREA_ID,
                COURSE_ID: lesson.courseInfo.COURSE_ID,
              },
              byTeachers: byTeachers,
            },
            token: token,
            school: this.userData.school._esis_schoolInfo,
            program: {
              STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
              STUDENT_GROUP_NAME: this.selectedProgram.STUDENT_GROUP_NAME,
              ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
              PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            },
          };

          try {
            await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/createEeljitLessonForUnelgee",
                requestBody,
                headers
              )
              .then((res) => {
                console.log(res);
                if (res.status === 200 && res.data.statusCode === 200) {
                  lesson.lessonSavingLoading = false;
                  lesson.esisSavedLessonData = res.data.esisSavedLessonData;
                  this.$forceUpdate();
                } else {
                  lesson.lessonSavingLoading = false;
                  this.$swal.fire(
                    "(" +
                      lesson.courseInfo.COURSE_NAME +
                      ") ESIS алдаа гарлаа. " +
                      (res.data.failedLessons
                        ? lesson.byTeachers.find(
                            (teacher) =>
                              teacher.teacherId ==
                              res.data.failedLessons[0].teacherId
                          ).teacherDisplayName + " багшийг олдохгүй байна. "
                        : "") +
                      " Та хүлээж байгаад дахин оролдоно уу!"
                  );
                  this.$forceUpdate();
                  return false;
                }
              });
          } catch (e) {
            console.log(e);
            lesson.lessonSavingLoading = false;
            this.$swal.fire(
              "(" +
                lesson.courseInfo.COURSE_NAME +
                ") ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );
            this.$forceUpdate();
            return false;
          }
        }
      } else {
        this.$swal
          .fire({
            title: "Ээлжит хичээл үүсгэх үү?",
            showCancelButton: true,
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
            customClass: {
              cancelButton: "swal2-cancel-button-custom",
              confirmButton: "swal2-ok-button-custom",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (!lesson.lessonSavingLoading) {
                lesson.lessonSavingLoading = true;
                this.$forceUpdate();
                var token = false;
                var tries = 10;
                try {
                  while (token == false && tries > 0) {
                    token = await axios
                      .post(
                        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                        {
                          username: this.userData.school._esisUserName,
                          password: this.userData.school._esisPword,
                        }
                      )
                      .then((res) => {
                        if (res.status === 200) {
                          return res.data.token;
                        } else {
                          return false;
                        }
                      });
                    tries--;
                  }
                } catch (e) {
                  lesson.lessonSavingLoading = false;
                  this.$forceUpdate();
                  this.$swal.fire(
                    "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
                  );
                  return false;
                }
                const headers = {
                  "Content-Type": "application/json",
                };

                var requestBody = {
                  lesson: {
                    refPath: lesson.ref.path,
                    id: lesson.id,
                    courseInfo: {
                      ENROLLMENT_CATEGORY:
                        lesson.courseInfo.ENROLLMENT_CATEGORY,
                      SUBJECT_AREA_ID: lesson.courseInfo.SUBJECT_AREA_ID,
                      COURSE_ID: lesson.courseInfo.COURSE_ID,
                    },
                    byTeachers: byTeachers,
                  },
                  token: token,
                  school: this.userData.school._esis_schoolInfo,
                  program: {
                    STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
                    STUDENT_GROUP_NAME: this.selectedProgram.STUDENT_GROUP_NAME,
                    ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
                    PROGRAM_OF_STUDY_ID:
                      this.selectedProgram.PROGRAM_OF_STUDY_ID,
                  },
                };

                try {
                  await axios
                    .post(
                      "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/createEeljitLessonForUnelgee",
                      requestBody,
                      headers
                    )
                    .then((res) => {
                      console.log(res);
                      if (res.status === 200 && res.data.statusCode === 200) {
                        lesson.lessonSavingLoading = false;
                        lesson.esisSavedLessonData =
                          res.data.esisSavedLessonData;
                        this.$forceUpdate();
                      } else {
                        lesson.lessonSavingLoading = false;
                        this.$swal.fire(
                          "ESIS алдаа гарлаа. " +
                            (res.data.failedLessons
                              ? lesson.byTeachers.find(
                                  (teacher) =>
                                    teacher.teacherId ==
                                    res.data.failedLessons[0].teacherId
                                ).teacherDisplayName +
                                " багшийг олдохгүй байна. "
                              : "") +
                            " Та хүлээж байгаад дахин оролдоно уу!"
                        );
                        this.$forceUpdate();
                        return false;
                      }
                    });
                } catch (e) {
                  console.log(e);
                  lesson.lessonSavingLoading = false;
                  this.$swal.fire(
                    "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
                  );
                  this.$forceUpdate();
                  return false;
                }
              }
            }
          });
      }
    },
    async saveGradesToEsis(lesson, openDialog) {
      if (!lesson.scoresSavingLoading) {
        lesson.scoresSavingLoading = true;
        this.$forceUpdate();
        let tries = 10;
        var token = false;

        try {
          while (token == false && tries > 0) {
            token = await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.userData.school._esisUserName,
                  password: this.userData.school._esisPword,
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  return res.data.token;
                } else {
                  return false;
                }
              });
            tries--;
          }
        } catch (e) {
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }

        if (!token) {
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }

        if (this.userData.role == "superadmin") {
          console.log(token);
        }

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: token,
        };

        try {
          var response2 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling students failed");
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response2.status == true) {
          var notEqualProgramPlanId = false;
          response2.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            lesson.scoresSavingLoading = false;
            this.$swal.fire(
              "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
            );

            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            lesson.scoresSavingLoading = false;
            this.$swal.fire("ESIS алдаа гарлаа. Анги дэвшсэн байна!");

            return false;
          }
        }
        console.log(response2);
        console.log(
          "PROGRAM_OF_STUDY_ID:" +
            PROGRAM_OF_STUDY_ID +
            ", " +
            "PROGRAM_STAGE_ID:" +
            PROGRAM_STAGE_ID +
            ", PROGRAM_PLAN_ID: " +
            programPlanId
        );
        console.log(this.selectedProgram);
        var courseBody = {
          program: {
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
          },
          programPlanId: programPlanId,
          token: token,
        };
        try {
          var response3 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
              courseBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling courses failed");
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        console.log(response3);
        if (response3.status == false) {
          console.log("pulling courses failed, " + response3.reason);
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          return false;
        }
        if (response3.status == true) {
          if (response3.courses && response3.courses.length > 0) {
            lesson.courseData = response3.courses.find(
              (course) =>
                course.COURSE_ID == lesson.courseInfo.COURSE_ID ||
                (course.SUBJECT_AREA_ID == lesson.courseInfo.SUBJECT_AREA_ID &&
                  lesson.courseInfo.COURSE_NAME.includes(
                    course.COURSE_CLASSIFICATION_NAME.replace("хичээл", "")
                      .replace("судлах", "")
                      .trim()
                  ))
            );

            console.log(lesson.courseData);

            var coursePlan = await this.userData.school.ref
              .collection("schoolPrograms")
              .doc(String(this.selectedProgram.PROGRAM_OF_STUDY_ID))
              .collection("stages")
              .doc(String(this.selectedProgram.PROGRAM_STAGE_ID))
              .collection("plans")
              .doc(String(programPlanId))
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let coursePlan = doc.data();
                  coursePlan.ref = doc.ref;
                  coursePlan.id = doc.id;
                  return coursePlan;
                } else {
                  return {
                    PROGRAM_PLAN_NAME: "-",
                  };
                }
              });
            if (!lesson.courseData) {
              console.log("course missing");
              lesson.scoresSavingLoading = false;
              this.$swal.fire(
                "ESIS алдаа гарлаа." +
                  coursePlan.PROGRAM_PLAN_NAME +
                  " дугаартай хөтөлбөр дээр энэ хичээл нэмэгдэггүй байх магагдлалтай. Та хичээлийн хөтөлбөрдөө байгааг шалгаад байхгүй бол оруулаад дахин оролдоорой."
              );
              return false;
            }
          }
        }
        var requestBody = {
          GRADING_SCHEME_ID: lesson.courseData.GRADING_SCHEME_ID,
          token: token,
        };
        try {
          lesson.gradingPlans = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/gradeIdRetriever",
              requestBody,
              headers
            )
            .then((response) => {
              if (response.status == 200) {
                return response.data.RESULT;
              } else {
                return null;
              }
            });
        } catch (e) {
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            `ESIS алдаа гарлаа. "${lesson.courseData.GRADING_SCHEME_ID}" гэсэн дүнгийн схем ID алдаатай байна. Та дүнгийн схемээ шалгана уу!`
          );
          console.log(emptyGradeFormat, "empty", lesson.gradingPlans);
          return false;
        }

        response2.students
          .filter((stud) =>
            lesson.students.find(
              (student) => Number(student.PERSON_ID) == Number(stud.PERSON_ID)
            )
          )
          .forEach((student) => {
            let foundd = lesson.unelgee2Scores.filter(
              (studentScore) =>
                Number(student.PERSON_ID) == Number(studentScore.PERSON_ID)
            );
            var score = null;
            foundd.forEach((studentScore) => {
              if (score == null) score = 0;
              if (lesson.gradingMethod == 0) {
                score = score + Number(studentScore.scoreFinal);
              } else if (lesson.gradingMethod == 1) {
                if (Number(studentScore.scoreFinal) > score) {
                  score = Number(studentScore.scoreFinal);
                }
              } else if (lesson.gradingMethod == undefined) {
                score = Number(studentScore.scoreFinal);
              }
            });

            student.unelgeeScore = {
              PERSON_ID: student.PERSON_ID,
            };

            if (lesson.gradingMethod == 0) {
              student.unelgeeScore.scoreFinal = Number(
                Math.ceil(score / foundd.length)
              );
            } else if (lesson.gradingMethod == 1) {
              student.unelgeeScore.scoreFinal = Number(score);
            } else if (lesson.gradingMethod == undefined) {
              student.unelgeeScore.scoreFinal = Number(score);
            }

            if (
              lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              lesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (lesson.courseInfo.GRADING_SCHEME_ID == 14) {
                if (Number(student.unelgeeScore.scoreFinal) >= 1) {
                  student.unelgeeScore.scoreFinal = 2;
                }
              } else {
                if (Number(student.unelgeeScore.scoreFinal) > 1) {
                  student.unelgeeScore.scoreFinal = 1;
                }
              }
            }
          });

        var tmp = {
          lessonRef: lesson.ref,
          programRef: this.selectedProgram.ref,
          package: {
            programElementId: Number(lesson.courseData.PROGRAM_ELEMENT_ID),
            studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
            academicLevel: Number(this.selectedProgram.ACADEMIC_LEVEL),
            event: "ADD",
            courseGradeArray: [],
          },
        };

        var emptyGradeFormat = false;
        response2.students
          .filter((stud) =>
            lesson.students.find(
              (student) => Number(student.PERSON_ID) == Number(stud.PERSON_ID)
            )
          )
          .forEach((student) => {
            let gradeFormat = lesson.gradingPlans.find(
              (grade) =>
                grade.MAX_GRADE_MARK >=
                  Number(student.unelgeeScore.scoreFinal) &&
                grade.MIN_GRADE_MARK < Number(student.unelgeeScore.scoreFinal)
            );
            if (gradeFormat) {
              if (Number(student.unelgeeScore.scoreFinal) > 0) {
                console.log({
                  FIRST_NAME: lesson.students.find(
                    (stud) =>
                      Number(student.PERSON_ID) == Number(stud.PERSON_ID)
                  ).FIRST_NAME,
                  PERSON_ID: Number(student.PERSON_ID),
                  GRADE_MARK: Number(student.unelgeeScore.scoreFinal),
                  AVG_CONTACT_HOURS: this.calculateAvgContactHours(
                    lesson,
                    this.selectedProgram
                  ),
                  COURSE_CONTACT_HOURS: lesson.courseData.COURSE_CONTACT_HOURS,
                });
                tmp.package.courseGradeArray.push({
                  PERSON_ID: Number(student.PERSON_ID),
                  GRADE_MARK: Number(student.unelgeeScore.scoreFinal),
                  GRADE_ID: gradeFormat.GRADE_ID,
                  GRADE_CODE: gradeFormat.GRADE_CODE,
                  GRADE_POINTS: gradeFormat.GRADE_POINTS,
                  GRADE_OUTCOME: gradeFormat.GRADE_OUTCOME,
                  AVG_CONTACT_HOURS: this.calculateAvgContactHours(
                    lesson,
                    this.selectedProgram
                  ), //TSAG END
                });
              }
            } else {
              emptyGradeFormat = true;
            }
          });
        if (emptyGradeFormat) {
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          console.log(emptyGradeFormat, "empty", lesson.gradingPlans);
          return false;
        }
        console.log(tmp.package, "GRADES POSTMAN HERE");
        console.log(
          "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
            PROGRAM_OF_STUDY_ID +
            "/" +
            PROGRAM_STAGE_ID +
            "/" +
            programPlanId
        );
        try {
          await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/sendFinalGradesToEsis",
              { token: token, grades: tmp.package },
              headers
            )
            .then((res) => {
              console.log(res);
              if (res.status === 200 && res.data.status === 200) {
                if (openDialog)
                  this.$swal.fire("Дүнг ЭСИС-т давхар xадгалагдсан!");
                var tmpp = {
                  finalGradeSavedToEsisCounter:
                    lesson.finalGradeSavedToEsis == true ? 2 : 1,
                  finalGradeSavedToEsis: true,
                  finalGradeSavedToEsisAt: new Date(),
                  finalGradeSavedByRef: this.userData.ref,
                  xProgramPlanId: programPlanId,
                  AVG_CONTACT_HOURS: this.calculateAvgContactHours(
                    lesson,
                    this.selectedProgram
                  ),
                };
                if (typeof lesson.gradingMethod == "number") {
                  tmpp.GRADING_METHOD_USED_FOR_FINAL_GRADE =
                    lesson.gradingMethod;
                }
                lesson.ref.update(tmpp).then(() => {
                  lesson.finalGradeSavedToEsisCounter =
                    lesson.finalGradeSavedToEsis == true ? 2 : 1;
                  lesson.finalGradeSavedToEsis = true;
                  lesson.finalGradeSavedToEsisAt = new Date();
                  lesson.GRADING_METHOD_USED_FOR_FINAL_GRADE =
                    lesson.gradingMethod;
                  lesson.AVG_CONTACT_HOURS = this.calculateAvgContactHours(
                    lesson,
                    this.selectedProgram
                  );
                  this.$forceUpdate();
                  this.selectedProgram["savedGradesToEsis-3"] = {
                    successfulLessonCounter: 0,
                    totalLessonCounter: 0,
                  };
                  this.filteredLessons
                    .filter(
                      (lsn) => !lsn.courseInfo.COURSE_NAME.includes("БЗЦ")
                    )
                    .forEach((lsn) => {
                      if (lsn.finalGradeSavedToEsis) {
                        this.selectedProgram["savedGradesToEsis-3"]
                          .successfulLessonCounter++;
                      }
                      this.selectedProgram["savedGradesToEsis-3"]
                        .totalLessonCounter++;
                    });

                  this.selectedProgram.ref
                    .update({
                      "savedGradesToEsis-3":
                        this.selectedProgram["savedGradesToEsis-3"],
                    })
                    .then(() => {
                      console.log(this.selectedProgram["savedGradesToEsis-3"]);
                      lesson.scoresSavingLoading = false;
                      this.$forceUpdate();
                    });
                });
              } else {
                lesson.scoresSavingLoading = false;
                this.$swal.fire(
                  "ESIS алдаа гарлаа." +
                    res.data.message +
                    ". Та хүлээж байгаад дахин оролдоно уу!"
                );
              }
            });
        } catch (e) {
          lesson.scoresSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );

          console.log(e);
        }
      }
    },
    calculateAvgContactHours(lesson, program) {
      if (
        lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
        typeof program.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE == "number"
      ) {
        return program.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE;
      } else {
        if (lesson.courseData.AVG_CONTACT_HOURS > 1) {
          return lesson.courseData.AVG_CONTACT_HOURS;
        } else {
          var AVG_CONTACT_HOURS = null;
          if (program.ACADEMIC_LEVEL <= 5) {
            AVG_CONTACT_HOURS = lesson.courseData.COURSE_CONTACT_HOURS / 32;
            if (AVG_CONTACT_HOURS % 0.5) {
              AVG_CONTACT_HOURS = Math.round(
                lesson.courseData.COURSE_CONTACT_HOURS / 32
              );
            }
          } else if (
            program.ACADEMIC_LEVEL >= 6 &&
            program.ACADEMIC_LEVEL <= 9
          ) {
            AVG_CONTACT_HOURS = lesson.courseData.COURSE_CONTACT_HOURS / 33;
            if (AVG_CONTACT_HOURS % 0.5) {
              AVG_CONTACT_HOURS = Math.round(
                lesson.courseData.COURSE_CONTACT_HOURS / 33
              );
            }
          } else if (program.ACADEMIC_LEVEL >= 10) {
            AVG_CONTACT_HOURS = lesson.courseData.COURSE_CONTACT_HOURS / 34;
            if (AVG_CONTACT_HOURS % 0.5) {
              AVG_CONTACT_HOURS = Math.round(
                lesson.courseData.COURSE_CONTACT_HOURS / 34
              );
            }
          }

          return AVG_CONTACT_HOURS;
        }
      }
    },
    async checkIfTypeChanged(lesson, mode) {
      if (mode == 2) {
        var docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(lesson.id)
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            return docs.docs.filter((doc) => !doc.data().lessonGroupId).length;
          });
        return docSize;
      } else if (mode == 1) {
        docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            console.log(docs.size);
            return docs.docs.filter((doc) => doc.data().lessonGroupId).length;
          });
        return docSize;
      }
    },
    isThisSemesterLesson(lesson) {
      if (lesson) {
        if (lesson.startEnd) {
          if (this.selectedHalfYear.yearId > 1) {
            if (
              (lesson.startEnd["semester-3"] &&
                lesson.startEnd["semester-3"].available) ||
              (lesson.startEnd["semester-4"] &&
                lesson.startEnd["semester-4"].available)
            ) {
              lesson.isNotThisSemester = false;
            } else {
              lesson.isNotThisSemester = true;
            }
          } else {
            if (
              (lesson.startEnd["semester-1"] &&
                lesson.startEnd["semester-1"].available) ||
              (lesson.startEnd["semester-2"] &&
                lesson.startEnd["semester-2"].available)
            ) {
              lesson.isNotThisSemester = false;
            } else {
              lesson.isNotThisSemester = true;
            }
          }
        } else {
          lesson.isNotThisSemester = false;
        }
      }
    },
    readLessons() {
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where("classGroupRefs", "array-contains", this.selectedProgram.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          var reset = false;
          if (!this.lessons) {
            reset = true;
            this.lessons = [];
          }

          docs.forEach(async (doc) => {
            var notFound = false;
            if (!reset) {
              var lesson = this.lessons.find((lessn) => lessn.id == doc.id);
            } else {
              notFound = true;
              lesson = doc.data();
              lesson.ref = doc.ref;
              lesson.id = doc.id;
            }
            this.isThisSemesterLesson(lesson);
            if (lesson && !lesson.isNotThisSemester) {
              var byTeachers = [];
              lesson.byTeachers.forEach((teacher) => {
                if (
                  teacher &&
                  teacher.teacherId &&
                  byTeachers.find((tt) => tt.teacherId == teacher.teacherId) ==
                    undefined
                ) {
                  byTeachers.push(teacher);
                }
              });
              lesson.byTeachers = byTeachers;
              if (lesson.byTeachers && lesson.byTeachers.length > 0) {
                if (
                  this.selectedHalfYear.yearId == 1 &&
                  lesson.esisLessonType.esisLessonTypeId == 2 &&
                  this.userData.school.id == "xUL4ue608cCItYWRaly2" //Сэтгэмж
                ) {
                  var status = await this.checkIfTypeChanged(lesson, 2);
                  console.log(status, lesson.courseInfo.COURSE_NAME);
                  if (status > 0) {
                    lesson.esisLessonType.esisLessonTypeId = 1;
                  }
                }

                if (
                  // this.selectedHalfYear.yearId == 1 &&
                  lesson.esisLessonType.esisLessonTypeId == 1 &&
                  this.userData.school.id == "dYybsiHFDZ8zvT6D0gGj" //73
                ) {
                  status = await this.checkIfTypeChanged(lesson, 1);
                  console.log(status, lesson.courseInfo.COURSE_NAME);
                  if (status > 0) {
                    lesson.esisLessonType.esisLessonTypeId = 2;
                  }
                }
                lesson.students = this.selectedProgram.students;

                if (lesson.esisLessonType.esisLessonTypeId > 1) {
                  await this._findGroupStudents(lesson);
                } else {
                  lesson.students = this.selectedProgram.students;
                }
                await this.getUnelgeeData(lesson);

                if (reset || notFound) {
                  this.lessons.push(lesson);
                }
              }
            }

            this.$forceUpdate();
          });
        });
    },
    _print(item) {
      console.log(item, item.ref.path);
      console.log(this.currentSelectedLesson.students.length);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD");
      }
      return "-";
    },
    formatDate2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        try {
          var date = val.toDate();
        } catch {
          date = val;
        }

        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.vselectRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}
</style>
