<template>
  <v-card class="px-0 mt-0" height="500">
    <template>
      <div class="d-flex flex-row justify-end ma-0 px-10 pt-2">
        <div class="pt-2 blue--text">
          Нийт сурагчийн тоо: {{ totalNumberOfStudents }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          small
          style="color: white"
          class="bg-gradient-danger mr-10"
          @click="cancelAllScores()"
          >Бүгдийг цуцлах
        </v-btn>
        <v-btn
          small
          style="color: white"
          class="successButton"
          @click="approveAllScores()"
          >Бүгдийг баталгажуулах
        </v-btn>
      </div>
      <v-simple-table
        v-if="$attrs.lesson.unelgee2Scores && students"
        class="px-10 pt-2 pb-2 scoreTables"
        style="height: 100%; overflow-y: auto"
      >
        <tbody>
          <tr style="background-color: #ececec" class="mt-2 pt-2">
            <th class="font-weight-bold black--text text-center">No.</th>
            <th class="font-weight-bold black--text pl-2">Нэр</th>

            <th class="font-weight-bold black--text text-center pr-1">Бүлэг</th>
            <th class="font-weight-bold black--text text-center pl-0">
              <div class="d-flex flex-row ma-0">
                <v-col
                  cols="4"
                  style="
                    border-right: 2px solid #dddddd;
                    background-color: #c9daf8 !important;
                  "
                  class="pa-0"
                  >Үнэлгээ I-Дундаж</v-col
                >
                <v-col cols="8" class="pa-0">Үнэлгээ - I</v-col>
              </div>
            </th>
            <th class="font-weight-bold black--text text-center">Эцсийн дүн (%)</th>
            <th class="font-weight-bold black--text text-center">Түвшин</th>
            <th class="font-weight-bold black--text text-center">Төлөв</th>
          </tr>
          <tr v-for="(item, ii) in filteredScores" :key="ii + '-' + item.index">
            <template v-if="item.separator">
              <td
                colspan="8"
                class="pl-2"
                style="background-color: #ececec; height: 20px !important"
              >
                {{ item.teacherDisplayName }} {{ item.name }} ({{
                  item.numberOfStudents
                }})
              </td>
            </template>
            <template v-else>
              <td
                class="text-center pl-0"
                @click="_print(item)"
                style="width: 1%"
              >
                {{ item.index }}
              </td>
              <td class="px-2" style="width: 25%">
                {{ item["FIRST_NAME"]
                }}<span class="grey--text"
                  >, {{ item["LAST_NAME"] }}{{ _getBD(item) }}
                </span>
              </td>

              <td class="text-center pl-0" v-if="!item.noGrade">
                <span
                  v-if="item.studentData && item.studentData.STUDENT_GROUP_NAME"
                >
                  {{ item.studentData.STUDENT_GROUP_NAME }}
                </span>
                <span v-else>
                  {{ item.STUDENT_GROUP_NAME }}
                </span>
              </td>
              <td v-else class="text-center pl-0">
                {{ item.STUDENT_GROUP_NAME }}
              </td>
              <td class="text-center px-0">
                <div class="d-flex flex-row ma-0" style="height: 100%">
                  <v-col
                    cols="4"
                    class="pa-0 pt-1"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      $attrs.selectedGradingMethod == 0
                        ? 'background-color: #fdd270;'
                        : 'background-color: #c9daf8;'
                    "
                  >
                    {{ item.averageScore }}
                  </v-col>
                  <v-col
                    class="pa-0 pt-1"
                    v-for="(score, sindex) in item.unelgee1Scores"
                    :key="'score' + sindex + score.score"
                    :style="
                      ($attrs.selectedGradingMethod == 1 &&
                        score.score == item.highestScore) ||
                      ($attrs.selectedGradingMethod == 2 &&
                        score.score == item.latestScore)
                        ? 'background-color: #fdd270;'
                        : '' +
                          (sindex + 1 == item.unelgee1Scores.length
                            ? ''
                            : 'border-right: 2px solid #dddddd')
                    "
                    :class="
                      sindex + 1 == item.unelgee1Scores.length ? '' : 'px-1'
                    "
                    >{{ score.score }}</v-col
                  >
                </div>
              </td>
              <td
                :title="
                  item.rejected && item.approved != false ? 'Засагдсан дүн' : ''
                "
                style="width: 15%"
                class="text-center pl-0"
                v-if="!item.noGrade"
              >
                {{ item.scoreFinal }}
              </td>
              <td v-else class="text-center pl-0 red--text">Дүн оруулаагүй</td>
              <td class="text-center blue--text pl-0" v-if="!item.noGrade">
                <span v-if="item.scoreFinal == 0" class="red--text">
                  {{ item.scoreLevelString }}</span
                >
                <span v-else> {{ item.scoreLevelString }}</span>
              </td>
              <td class="text-center pl-0" v-else>-</td>

              <td class="pl-1 text-center" v-if="!item.noGrade">
                <v-btn
                  x-small
                  @click="changeStatus(item)"
                  :class="
                    item.approved == true
                      ? 'successButton'
                      : item.approved == false
                      ? 'bg-gradient-danger'
                      : 'inProgressButton'
                  "
                  class="white--text ml-2 mr-3 text-capitalize"
                  v-text="
                    item.approved == true
                      ? 'Баталгаажсан'
                      : item.approved == false
                      ? 'Цуцлагдсан'
                      : 'Хянагдаж байгаа'
                  "
                >
                </v-btn>
              </td>
              <td class="text-center pl-0" v-else>-</td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="mt-16 pt-16">
        <v-progress-linear
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
    </template>
  </v-card>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    studentsStatuses: null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,
    xlessonStudentNotes: null,
    noteAdded: null,
    dialogAddNote: false,

    query: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
    headerNames: [
      {
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ],
    attendances: null,
    loading: false,
    students: null,
    totalNumberOfStudents: 0,
  }),
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    _getFilteredGroups() {
      // $attrs.lesson.lessonGroups
      var list = [];
      for (const lg of this.$attrs.lesson.lessonGroups) {
        if (
          this.$attrs.currentSelectedExam.lessonGroup &&
          lg.id == this.$attrs.currentSelectedExam.lessonGroup.id
        ) {
          list.push(lg);
        } else if (!this.$attrs.currentSelectedExam.lessonGroup) {
          list.push(lg);
        }
      }
      return list;
    },
    filteredScores() {
      var list = [];
      var counter = 0;
      var prevGroupIndex;
      this.clearTotalNumber();
      if (this.students) {
        for (var student of this.students) {
          var score;
          if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
            if (student.lessonGroup && student.lessonGroup.groupIndex) {
              score = this.$attrs.lesson.unelgee2Scores.find(
                (score) =>
                  score.PERSON_ID == student.PERSON_ID &&
                  score.lessonGroupId == student.lessonGroup.id
              );
              if (
                !prevGroupIndex ||
                prevGroupIndex != student.lessonGroup.groupIndex
              ) {
                list.push({
                  numberOfStudents: student.lessonGroup.students.length,
                  index: student.lessonGroup.groupIndex,
                  name: student.lessonGroup.name,
                  separator: true,
                  teacherDisplayName: student.lessonGroup.teacher
                    ? student.lessonGroup.teacher.teacherDisplayName
                    : "-",
                });
                this.setNumberOfStudents(
                  student.lessonGroup.students.length,
                  true
                );

                counter = 0;
              }
              prevGroupIndex = student.lessonGroup.groupIndex;
            }
          } else {
            score = this.$attrs.lesson.unelgee2Scores.find(
              (score) => score.PERSON_ID == student.PERSON_ID
            );
            this.setNumberOfStudents(this.students.length, false);
          }
          if (score) {
            if (
              score.score == undefined ||
              score.score == null ||
              typeof score.score != "number" ||
              !score.sentToManager
            ) {
              counter++;
              student.index = counter;
              student.approved = null;
              student.noGrade = true;
              list.push(student);
            } else {
              if (score.statusModified == undefined) {
                score.statusModified = false;
              }
              counter++;
              score.index = counter;
              if (score.approved == undefined) {
                score.approved = null;
              }
              if (score.scoreFinal == undefined || score.scoreFinal == null) {
                score.scoreFinal = score.score;
              }
              list.push(score);
            }
          } else {
            counter++;
            student.index = counter;
            student.approved = null;
            student.noGrade = true;
            list.push(student);
          }
        }
      }
      return list;
    },
  },
  async created() {
    if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
      if (this.$attrs.lesson.unelgee2Scores) {
        this.$attrs.lesson.unelgee2Scores.forEach((student) => {
          this.getUnelgee1Scores(student);
        });
      }
      setTimeout(() => {
        this.students = this.$attrs.lesson.students.sort(
          (a, b) => a.lessonGroup.groupIndex - b.lessonGroup.groupIndex
        );
      }, 1000);
    } else {
      if (this.$attrs.lesson.unelgee2Scores) {
        this.$attrs.lesson.unelgee2Scores.forEach((student) => {
          this.getUnelgee1Scores(student);
        });
      }
      this.students = this.$attrs.lesson.students;
    }
  },

  methods: {
    _getBD(student) {
      if (student["DATE_OF_BIRTH"])
        return ", " + student["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "");
      else return "";
    },
    clearTotalNumber() {
      this.totalNumberOfStudents = 0;
    },
    getUnelgee1Scores(student) {
      var scores;
      if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
        scores = this.$attrs.lesson.unelgee1Scores.filter(
          (score) =>
            score.PERSON_ID == student.PERSON_ID &&
            score.courseInfo.COURSE_ID ==
              this.$attrs.lesson.courseInfo.COURSE_ID &&
            score.lessonGroupId == student.lessonGroupId
        );
      } else {
        scores = this.$attrs.lesson.unelgee1Scores.filter(
          (score) =>
            score.PERSON_ID == student.PERSON_ID &&
            score.courseInfo.COURSE_ID ==
              this.$attrs.lesson.courseInfo.COURSE_ID
        );
      }
      scores = scores.sort((a, b) => a.examDate - b.examDate);
      if (scores.length > 0) {
        var highestScore = null;
        var averageScore = 0;
        var latestScore = null;
        scores.forEach((score) => {
          if (
            (highestScore && highestScore.score < score.score) ||
            highestScore == null
          ) {
            highestScore = score;
          }
          if (
            (latestScore && latestScore.examDate < score.examDate) ||
            latestScore == null
          ) {
            latestScore = score;
          }
          averageScore = averageScore + score.score;
        });
        averageScore = Number((averageScore / scores.length).toFixed(0));
        student.highestScore = highestScore.score;
        student.highestScoreExam = highestScore;
        student.latestScore = latestScore.score;
        student.latestScoreExam = latestScore;
        student.unelgee1Scores = scores;

        student.averageScore = averageScore;
        this.$forceUpdate();

        return averageScore;
      } else {
        return "-";
      }
    },

    setNumberOfStudents(value, status) {
      if (status) {
        this.totalNumberOfStudents = this.totalNumberOfStudents + value;
      } else {
        this.totalNumberOfStudents = value;
      }
    },
    approveAllScores() {
      for (var score of this.filteredScores) {
        if (!score.separator)
          if (!score.noGrade) {
            score.statusModified = true;
            score.approved = true;
          }
      }
      this.$forceUpdate();
    },
    cancelAllScores() {
      this.$swal({
        title: "Бүх дүнг цуцлах уу?",
        text: "Энэ үйлдлийг хийсэнээр бүх дүн дээр цуцлах гарч ирнэ. Хадгалвал БҮРЭН бүх дүн цүцлагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          for (var score of this.filteredScores) {
            if (!score.separator)
              if (!score.noGrade) {
                score.statusModified = true;
                score.approved = false;
              }
          } //444
          this.$forceUpdate();
        }
      });
    },
    changeStatus(item) {
      console.log(item);
      if (item.approved == undefined || item.approved == null) {
        item.approved = true;
      } else if (item.approved == true) {
        item.approved = false;
      } else if (item.approved == false) {
        item.approved = null;
      }
      item.statusModified = true;
      this.$forceUpdate();
    },
    calScoreLevel(item) {
      item.scoreFinal = null;

      item.scoreFinal =
        item.score != null &&
        item.score != undefined &&
        typeof item.score != "number"
          ? item.score
          : 0;
      if (item.scoreFinal > 100) {
        this.$swal.fire("100>>");
        return item.score;
      }
      let gradePoints;
      let gradeStr;
      let tmpScore = item.scoreFinal;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }
      item.scoreLevelNumber = gradePoints;
      item.scoreLevelString = gradeStr;

      this.$forceUpdate();
    },
    _print(item) {
      console.log("xxx", item.ref.path);
    },
  },
};
</script>
<style>
.successButton {
  background-color: #20ac20 !important;
}
.inProgressButton {
  background-color: #ffbf00 !important;
}
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 35px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
